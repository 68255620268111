import React from "react";
import { FormattedMessage } from "react-intl";
import * as types from "../constants/ActionTypes";
import * as profileServiceApi from "../api/profileServiceApi";
import { notify, notifyError } from "../actions/notificationsActions";

export const updatePassword = passwordInfo => dispatch => {
  dispatch({ type: types.SET_PASSWORD_REQUEST });
  return profileServiceApi
    .updatePassword({
      current_password: passwordInfo.currentPassword,
      new_password: passwordInfo.newPassword
    })
    .then(
      result => {
        dispatch({ type: types.SET_PASSWORD_RECEIVED });

        dispatch(
          notify({
            message: (
              <FormattedMessage
                defaultMessage="You have successfully updated your password."
                id="passwordUpdatedSuccessfully"
              />
            ),
            type: "success",
            isDismissable: true
          })
        );

        dispatch({ type: types.SET_PASSWORD_RESET });

        return true;
      },
      error => {
        const message = (error.normalized &&
          error.normalized.body &&
          error.normalized.body.message) || (
          <FormattedMessage
            defaultMessage="Password update failed"
            id="passwordUpdateFailure"
          />
        );

        dispatch({ type: types.SET_PASSWORD_ERROR });
        dispatch(
          notifyError({
            message,
            isDismissable: true
          })
        );
        return false;
      }
    );
};

export const fetchPasswordPolicy = () => dispatch => {
  return profileServiceApi.fetchPasswordPolicy().then(
    data => {
      dispatch({
        type: types.FETCH_POLICY_RECEIVED,
        passwordPolicy: data.password_rules,
        passwordRedirectInfo: data.password_redirect_info
      });
    },
    error => {
      dispatch({
        type: types.FETCH_POLICY_ERROR
      });
    }
  );
};
